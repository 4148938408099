/* global HTMLElement, CustomEvent, window */

class QuizQuestion extends HTMLElement {

	constructor() {

		super();

		this.answers = [];

		this._nextChapterButton = this.querySelector('.js-next-chapter');
		this._nextQuestionButton = this.querySelector('.js-next-question');

		this._setupAnswerSelectListener();
		this._setupRegisterAnswerListener();
		this._setupNextChapterListener();
		this._setupNextQuestionListener();

	}


	connectedCallback() {
		this._registerQuestion();
	}


	/**
	* Any question-answer fires select-answer when it is selected.
	* Disable all answers if this happens.
	*/
	_setupAnswerSelectListener() {
	
		this.addEventListener('select-answer', (ev) => {
			
			// Disable all answers (to prevent users from correcting their wrong answers, indeed)
			this.answers.forEach((answer) => answer.disable());
			
			// Enbable «next question» button
			if (this._nextQuestionButton) this._nextQuestionButton.removeAttribute('disabled');
			
			// Enable «next chapter» (if set)
			if (this._nextChapterButton) this._nextChapterButton.removeAttribute('disabled');

			// Add attributes answered and answer-correct/-wrong (to highlight correct answer through CSS)
			this.setAttribute('answered', '');
			const correctnessAttributeName = ev.detail.correct ? 'answer-correct' : 'answer-wrong';
			this.setAttribute(correctnessAttributeName, '');
			console.log('QuizQuestion: Set attributes answered and %o', correctnessAttributeName);

		});
	
	}


	/**
	* Go to next question (interact with Flickity)
	*/
	_setupNextQuestionListener() {
		if (!this._nextQuestionButton) return;
		this._nextQuestionButton.addEventListener('click', (ev) => {
			ev.preventDefault();
			const event = new CustomEvent('next-question', { bubbles: true });
			this.dispatchEvent(event);
		});
	}


	/**
	* If user clicks on «Next chapter» button, wait until all promises (store answers, 
	* see quiz-questions) are resolved.
	*/
	_setupNextChapterListener() {

		if (!this._nextChapterButton) return;
		this._nextChapterButton.addEventListener('click', (ev) => {
			
			ev.preventDefault();

			console.log('QuizQuestion: Button to next chapter clicked; wait for promises to resolve');

			// Wait until all answers were stored
			this._questionSet
				.waitForStoreAnswerPromises()
				.then(() => {
					window.location.href = ev.target.getAttribute('data-href');
				});
		});

	}


	/**
	* New answer added: question-answer emits register-answer.
	*/
	_setupRegisterAnswerListener() {

		this.addEventListener('register-answer', (ev) => {
			console.log('QuizQuestion: Answer %o registered', ev);
			this.answers.push(ev.detail.element);
		});

	}


	/**
	* Register myself at quiz-questions. Why?
	* We should wait with redirecting the user to the next page (next chapter)
	* until all calls (store answer) are made.
	*/
	_registerQuestion() {
		const event = new CustomEvent('register-question', { detail: { element: this }, bubbles: true });
		this.dispatchEvent(event);
	}



	/**
	* Called from parent when component is registered
	*/
	registerQuestionSet(questionSet) {

		this._questionSet = questionSet;
		console.log('QuizQuestion: Question set %o registered', this._questionSet);

	}


}

window.customElements.define('quiz-question', QuizQuestion);